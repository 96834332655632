/**
 * Created by r4zi4l on 29.07.2022
 */

cleverapps.unitsDemoMode = function () {
    const params = cleverapps.getRequestParameters();
    if (params.unitsDemo === undefined || !cleverapps.config.debugMode) {
        return;
    }

    cleverapps.config.demoMode = true;

    connector.dataLoader.setEnabled(false);

    DataLoader.prototype.resetClientSession = function () {
        this.setAlerted(true);
        this.setEnabled(false);
        this.setCorrupted(false);
    };

    AdminEpisodes.prototype.reset = function () {

    };

    Merge.prototype.loadStaticUnits = function () {
        cleverapps.unitsDemoMode.addUnits(this.map);
    };

    cleverapps.Plot.onStartup = function (f, returnClassOnly) {
        cleverapps.config.adminMode = true;

        cleverapps.flags.norest = true;
        cleverapps.flags.nologin = true;

        if (returnClassOnly) {
            return cleverapps.meta.getMainScene();
        }

        cleverapps.unitsDemoMode.gotoUnitsDemoScene(f);
    };

    Toolbar.prototype.addItem = function () {};
    SideBar.prototype.addTemporaryIcon = function () {};
    SideBar.prototype.loadAvailable = function () {
        return [];
    };
};

cleverapps.unitsDemoMode.gotoUnitsDemoScene = function (f) {
    const params = cleverapps.getRequestParameters();

    let locationId = params.unitsDemo;
    if (!locationId) {
        locationId = "main";
    }

    if (params.code && Families.codes.includes(params.code) && Families[params.code].expeditions.includes(locationId)) {
        this.unitCode = params.code;
    }

    const originalBundle = bundles[`location_${locationId}`];

    const demoBundle = bundles[`location_${locationId}`] = cleverapps.clone(bundles["location_editor"], true);
    demoBundle.meta.units = originalBundle.meta.units;
    demoBundle.meta.tiles = originalBundle.meta.tiles;

    cleverapps.meta.switchLocation(locationId);

    cleverapps.meta.gotoLocationScene(f);
};

cleverapps.unitsDemoMode.addUnits = function (map) {
    const getGrounds = function (y1) {
        const ground = [];
        for (let x = 0; x < map.getWidth(); x++) {
            if (map.isGround(x, y1)) {
                ground.push(cc.p(x, y1));
            }
        }
        return ground;
    };

    const rows = [];
    for (let rowInd = 0; rowInd < map.getHeight(); rowInd++) {
        const grounds = getGrounds(rowInd);
        if (grounds.length) {
            rows.push(grounds);
        }
    }

    console.trace();
    const groups = cleverapps.unitsDemoMode.getUnitsGroups(map);
    let groupInd = 0;
    let relatedUnits = [];

    const loadNextGroup = function () {
        const nextGroup = groups[groupInd++];
        relatedUnits = relatedUnits.concat(nextGroup.units.slice());
    };

    const isSameType = function () {
        const prevGroup = groups[groupInd - 1];
        const nextGroup = groups[groupInd];
        return nextGroup && nextGroup.type === prevGroup.type;
    };

    const spawnUnits = function (units, cells, cellsUsedX) {
        const start = Math.floor((cells.length - cellsUsedX) / 2);
        cells = cells.slice(start, start + cellsUsedX);

        units.forEach((unit) => {
            unit.factory(cells[0].x, cells[0].y);
            cells.splice(0, unit.cellsUsedX);
        });
    };

    for (let rowInd = 0; rowInd < rows.length;) {
        while (groupInd < groups.length && (relatedUnits.length === 0 || isSameType())) {
            loadNextGroup();
        }

        if (!relatedUnits.length) {
            return;
        }
        
        const row = rows[rowInd];
        const inRowUnits = [];

        const paddingCells = 4;
        let occupiedX = 0, occupiedY = 0;
        while ((row.length - paddingCells) > occupiedX && relatedUnits.length > 0) {
            inRowUnits.push(relatedUnits.shift());
            occupiedX += inRowUnits.at(-1).cellsUsedX;
            occupiedY = Math.max(occupiedY, inRowUnits.at(-1).cellsUsedY);
        }
        spawnUnits(inRowUnits, row, occupiedX);

        rowInd += occupiedY + (relatedUnits.length > 0 ? 0 : 1);
    }

    const remainingGroups = groups.slice(groupInd);
    if (remainingGroups.length) {
        console.error("Remaining groups:", remainingGroups);
        cleverapps.throwAsync("Unable to place all groups in unitsDemo");
    }
};

cleverapps.unitsDemoMode.getUnitsGroups = function (map) {
    const groups = [];

    const codes = this.selectedUnit() ? [this.selectedUnit()] : Families.codes;

    codes.forEach((code) => {
        if (["unknown", "multiCellBody", "thirdelement", "t", "initialize", "landmarkspot"].includes(code)) {
            return;
        }

        const location = cleverapps.meta.getSelectedLocation();
        if (location.families && !location.families[code]) {
            return;
        }

        console.log(`Has ${code}`);

        const family = Families[code];
        const group = {
            code,
            type: family.type,
            layer: Map2d.LAYER_UNITS,
            units: []
        };

        let stages = family.units.length - 1;
        if (family.units[0].mission && !this.selectedUnit()) {
            stages = 0;
        }

        cleverapps.rangeArray(0, stages).forEach((stage) => {
            const unit = new Unit({
                code,
                stage
            });
            unit.chooser = true;

            if (!UnitView.UnitIconFrame(unit) && !UnitView.UnitIconJson(unit)) {
                console.log(`No frame for ${code}_${stage + 1}`);
                return;
            }

            if (Families[code].units[stage].deleted) {
                return;
            }

            const cellsUsed = {
                x: 1,
                y: 1
            };

            if (unit.getShape().length > 1) {
                cellsUsed.x = 3;
                cellsUsed.y = 2;
            }

            if (this.selectedUnit()) {
                cellsUsed.x = 4;
                cellsUsed.y = 5;
            }

            group.units.push({
                cellsUsedX: cellsUsed.x,
                cellsUsedY: cellsUsed.y,
                factory: function (x, y) {
                    unit.setPosition(x, y);
                    map.add(Map2d.LAYER_UNITS, x, y, unit);
                    return unit;
                }
            });
        });

        groups.push(group);
    });

    return groups;
};

cleverapps.unitsDemoMode.selectedUnit = function () {
    return this.unitCode;
};