/**
 * Created by mac on 12/26/22
 */

const MergeOrangery = {
    init: function () {
        const tabs = [];
        const tabIcons = this.getTabIcons();
        this.generateTabCodes();

        const codesByTab = this.groupFamiliesByTab();
        for (const tabId in codesByTab) {
            tabs.push({
                icon: tabIcons[tabId],
                rows: codesByTab[tabId].map((family) => this.listUnitsByCode(family, true))
            });
            if (this.preSelectedTab === true) {
                this.preSelectedTab = tabId;
            }
        }

        if (cleverapps.config.editorMode) {
            this.addEditorTabs(tabs);
        }

        tabs.forEach((tab) => {
            tab.rows.unshift([{
                eraser: true,
                id: tab.id,
                itemIcon: new cc.Sprite(bundles.orangery.frames.eraser_icon),
                minimizedIcon: new cc.Sprite(bundles.orangery.frames.eraser_icon)
            }]);
        });

        this.tabs = tabs;
    },

    clear: function () {
        this.tabs = [];
    },

    generateTabCodes: function () {
        this.codesByTabId = [
            [],
            [],
            [],
            [],
            ["rubiesplantchest"],
            ["rubiesplant", "coinsplant", "energyplant"],
            ["season0", "season1", "season2", "season3", "season4", "season5", "season6", "season7", "season8", "season9", "season10", "season11"],
            ["landmark", "landmarkspot", "debugunit"]
        ];
        this.codesByTabId[3] = this.codesByTabId[3].concat(this.listGenerators()).concat(this.listCastles());

        if (cleverapps.meta.selectedLocationId() === "collections") {
            this.codesByTabId.splice(7, 1, [], [], ["landmark", "landmarkspot", "clnarrator", "clshop"]);
        }
    },

    getTabIcons: function () {
        let icons = [
            bundles.windows.frames.tab_icon_heroes,
            bundles.windows.frames.tab_icon_resource,
            bundles.windows.frames.tab_icon_fruit,
            bundles.windows.frames.tab_icon_source,
            bundles.windows.frames.tab_icon_chest,
            bundles.windows.frames.tab_icon_valuable,
            bundles.windows.frames.tab_icon_ingredients,
            bundles.windows.frames.tab_icon_landmark
        ];

        if (cleverapps.meta.selectedLocationId() === "collections") {
            icons.shift();
            icons = [bundles.pet_tab_icons.frames.tab_icon_regular, bundles.pet_tab_icons.frames.tab_icon_rare, bundles.pet_tab_icons.frames.tab_icon_legend].concat(icons);
        }

        return icons;
    },

    addEditorTabs: function (tabs) {
        tabs.push({
            icon: bundles.orangery.frames.tab_icon_ground,
            layer: Map2dEditor.LAYER_GROUND,
            rows: this.listTiles()
        });
        tabs.push({
            icon: bundles.orangery.frames.tab_icon_fogs,
            rows: this.listFogs(),
            id: MergeOrangery.REGION_ID
        });
        tabs.push({
            icon: bundles.orangery.frames.tab_icon_regions,
            rows: this.listRegions(),
            id: MergeOrangery.REGION_ID
        });
        tabs.push({
            icon: bundles.orangery.frames.tab_icon_decorators,
            rows: this.listDecorators(),
            layer: Map2dEditor.LAYER_DECORATORS,
            id: MergeOrangery.DECORATOR_ID
        });
        tabs.push({
            icon: bundles.orangery.frames.tab_icon_terrain,
            rows: this.listTerrains(),
            layer: Map2dEditor.LAYER_DECORATORS,
            id: MergeOrangery.DECORATOR_ID
        });
    },

    listDecorators: function () {
        const targetBundles = [
            Map2d.getTilesTexture(Map2d.currentMap.tilesSkin),
            Map2d.getUnitsTexture(Map2d.currentMap.unitsSkin)
        ];
        const decorators = Object.keys(bundles).filter((name) => {
            const bundle = bundles[name];
            return name.indexOf("decorator_") === 0
                && cleverapps.intersect(bundle.injectTo, targetBundles).length
                && (Object.values(bundle.frames)[0] || Object.values(bundle.jsons)[0]);
        }).sort((a, b) => {
            const aChess = a.indexOf("grounddecorator_") !== -1;
            const bChess = b.indexOf("grounddecorator_") !== -1;
            if (aChess && !bChess) {
                return -1;
            }
            if (!aChess && bChess) {
                return 1;
            }
            return 0;
        }).map((name) => {
            const decorator = new DecoratorUnit({ code: name.slice(10) });
            if (Object.values(bundles[name].frames)[0]) {
                const frame = Object.values(bundles[name].frames)[0];
                decorator.itemIcon = new cc.Sprite(frame);
                decorator.minimizedIcon = new cc.Sprite(frame);
            } else if (Object.values(bundles[name].jsons)[0]) {
                const json = Object.values(bundles[name].jsons)[0];
                decorator.itemIcon = new cleverapps.Spine(json);
                decorator.itemIcon.setAnimation(0, "animation", true);
                decorator.minimizedIcon = new cleverapps.Spine(json);
                decorator.minimizedIcon.setAnimation(0, "animation", true);
            }
            return decorator;
        });
        return cleverapps.splitToBatches(decorators, 13);
    },

    listTerrains: function () {
        const terrains = [];
        Object.values(Map2d.TERRAINS).forEach((terrain) => {
            const bundle = `${Map2d.getTilesTexture(Map2d.currentMap.tilesSkin)}_${terrain}`;

            if (!bundles[bundle]) {
                return;
            }

            for (const frameId in bundles[bundle].frames) {
                const frame = bundles[bundle].frames[frameId];

                const decorator = new DecoratorUnit({ code: `${Map2d.getTilesTexture(Map2d.currentMap.tilesSkin)}_${terrain}#${frame.key}` });

                decorator.itemIcon = new cc.Sprite(frame);
                decorator.minimizedIcon = new cc.Sprite(frame);
                terrains.push(decorator);
            }
        });
        return cleverapps.splitToBatches(terrains, 13);
    },

    generateFogTitle: function (code) {
        if (code.indexOf("fog") === 0 || code.indexOf("money") !== -1) {
            return cleverapps.UI.generateTTFText(`${code.replace(/[^0-9, _]/g, "")}`, cleverapps.styles.FONTS.BIG_BROWN_TITLE_TEXT);
        }
        let name = code.replace("fog", "");
        if (name.length > 5) {
            name = `${name.substring(0, name.length / 2)}\n${name.substring(name.length / 2, name.length)}`;
        }
        return cleverapps.UI.generateTTFText(name, cleverapps.styles.FONTS.SMALL_BROWN_TITLE_TEXT);
    },

    listFogs: function () {
        return cleverapps.splitToBatches(Object.keys(Map2d.currentMap.fogs.config).map((code, index) => {
            const fog = new RegionUnit({
                code,
                type: RegionUnit.TYPE_FOG
            });
            if (code.indexOf("fog") !== 0) {
                fog.bg = index % 2 === 0 ? bundles.orangery.frames.item_bg_money_dark : bundles.orangery.frames.item_bg_money_light;
            }
            fog.itemIcon = this.generateFogTitle(code);
            fog.minimizedIcon = this.generateFogTitle(code);
            return fog;
        }), 13);
    },

    listRegions: function () {
        const fogs = cleverapps.substract(Object.keys(Map2d.currentMap.regions), Object.keys(Map2d.currentMap.fogs.config));
        return [fogs.map((code) => {
            const region = new RegionUnit({
                code,
                type: RegionUnit.TYPE_REGION
            });
            region.itemIcon = this.generateFogTitle(code);
            region.minimizedIcon = this.generateFogTitle(code);
            return region;
        })];
    },

    groupFamiliesByTab: function () {
        const location = cleverapps.meta.getSelectedLocation();
        const codesByTab = {};

        Object.keys(location.families).forEach((code) => {
            if (["unknown", "multiCellBody", "thirdelement", "t"].indexOf(code) !== -1) {
                return;
            }
            if (["seasonchest"].includes(code) && location.locationId !== "main") {
                return;
            }
            
            let tabId = this.findTabId(code);
            if (tabId === undefined) {
                tabId = this.findTabId(code, location.locationId);
            }
            if (tabId === undefined) {
                tabId = this.findTabId(code, "main");
            }
            if (tabId === undefined) {
                tabId = MergeOrangery.OTHER_TAB;
            }

            if (!codesByTab[tabId]) {
                codesByTab[tabId] = [];
            }

            codesByTab[tabId].push(code);
        });

        return codesByTab;
    },

    findTabId: function (code, locationId) {
        const tabs = UnitsLibrary.codesByTabId[locationId] || this.codesByTabId;
        for (let i = 0; i < tabs.length; i++) {
            if (tabs[i].indexOf(code) !== -1) {
                return i;
            }
        }
    },

    listUnitsByCode: function (family, preferStatic) {
        const units = [];

        for (let stage = 0; stage < Families[family].units.length; ++stage) {
            const unit = {
                code: family,
                stage
            };

            if (Families[family].units[stage].deleted) {
                continue;
            }

            unit.itemIcon = UnitView.getUnitImage(unit, { preferStatic });
            unit.minimizedIcon = UnitView.getUnitImage(unit, { preferStatic });

            units.push(unit);
        }

        return units;
    },

    listTiles: function () {
        const tilesTexture = Map2d.getTilesTexture(Map2d.currentMap && Map2d.currentMap.tilesSkin);
        const res = bundles[tilesTexture] && bundles[tilesTexture].frames.water || bundles.dev_resources.frames.water;

        const water = [{
            tile: Map2d.TILE_WATER,
            itemIcon: new cc.Sprite(res),
            minimizedIcon: new cc.Sprite(res)
        }, {
            tile: Map2d.TILE_WATER_UNIT,
            itemIcon: new cc.Sprite(res),
            minimizedIcon: new cc.Sprite(res)
        }];

        water[1].itemIcon.setColor(new cc.Color(100, 100, 100, 255));
        water[1].minimizedIcon.setColor(new cc.Color(100, 100, 100, 255));

        const level1 = [];
        const level2 = [];

        const createImpassableGroundIcon = function (frame) {
            const icon = new cc.Sprite(frame);
            const asterisk = cleverapps.UI.generateImageText("*", cleverapps.styles.FONTS.WHITE_TEXT);
            icon.addChild(asterisk);
            asterisk.setPositionRound(icon.width / 2, icon.height / 2);

            return icon;
        };

        Object.keys(Map2d.TERRAINS).forEach((code) => {
            const terrain = Map2d.TERRAINS[code];

            const bundle = bundles[`${Map2d.getTilesTexture(Map2d.currentMap.tilesSkin)}_${terrain}`];
            if (!bundle) {
                return;
            }

            level1.push({
                tile: Map2d.TILE_GREEN_LEVEL_1,
                terrain: code,
                itemIcon: new cc.Sprite(bundle.frames.green_ground_1),
                minimizedIcon: new cc.Sprite(bundle.frames.green_ground_1)
            });

            level1.push({
                tile: Map2d.TILE_IMPASSABLE_LEVEL_1,
                terrain: code,
                itemIcon: createImpassableGroundIcon(bundle.frames.green_ground_1),
                minimizedIcon: createImpassableGroundIcon(bundle.frames.green_ground_1)
            });

            level2.push({
                tile: Map2d.TILE_GREEN_LEVEL_2,
                terrain: code,
                itemIcon: new cc.Sprite(bundle.frames.green_ground_2),
                minimizedIcon: new cc.Sprite(bundle.frames.green_ground_2)
            });

            level2.push({
                tile: Map2d.TILE_IMPASSABLE_LEVEL_2,
                terrain: code,
                itemIcon: createImpassableGroundIcon(bundle.frames.green_ground_2),
                minimizedIcon: createImpassableGroundIcon(bundle.frames.green_ground_2)
            });
        });

        return [water, level1, level2];
    },

    listGenerators: function () {
        const generators = [];

        Object.keys(Map2d.currentMap.families).forEach((code) => {
            if (code.includes("generator")) {
                generators.push(code);
            }
        });

        return generators;
    },

    listCastles: function () {
        const castles = [];

        Object.keys(Map2d.currentMap.families).forEach((code) => {
            if (code.includes("castle")) {
                castles.push(code);
            }
        });

        return castles;
    },

    getItemCode: function (item) {
        if (item.tile) {
            const terrain = Map2d.TERRAINS[item.terrain];
            return `tile_${item.tile}${terrain ? `_${terrain}` : ""}`;
        }
        return item.stage ? Unit.GetKey(item) : item.code;
    }
};

MergeOrangery.OTHER_TAB = 7;
MergeOrangery.DECORATOR_ID = 1;
MergeOrangery.REGION_ID = 2;